import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { BACKEND_URL } from "../../config";
import logo_dtc from "../../assets/medias/logo.svg";

const Reporting = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const monitoring = params.get('monitoring');
  const user = params.get('user');
  const sandbox = params.get('sandbox');
  const isLinked = params.get('isLinked') === 'true';
  const [displayName, setDisplayName] = useState('');
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [codeValidated, setCodeValidated] = useState(false);
  const [authorizationCode, setAuthorizationCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [assessmentName, setAssessmentName] = useState('');
  const [surveyData, setSurveyData] = useState([]);
  const [responseId, setResponseId] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentAssessmentId, setCurrentAssessmentId] = useState('');
  const [currentTextAnswer, setCurrentTextAnswer] = useState('');
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [matrixResponses, setMatrixResponses] = useState({});
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const assessmentIds = params.getAll('assessment[]');
  const [linkingIDValidated, setLinkingIDValidated] = useState(false);
  const [linkingCode, setLinkingCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestionIndex]);

  const generateUniqueAlphanumericCode = () => {
    const concatenatedIds = assessmentIds.sort().join('');
    let checksum = 0;
    for (let i = 0; i < concatenatedIds.length; i++) {
      checksum = (checksum * 31 + concatenatedIds.charCodeAt(i)) & 0xFFFFFFFF;
    }
    const base36String = (checksum >>> 0).toString(36).toUpperCase();
    return base36String.padEnd(6, base36String).slice(0, 6);
  };

  const validateCode = () => {
    const expectedCode = generateUniqueAlphanumericCode();
    if (authorizationCode === expectedCode) {
      setCodeValidated(true);
      setCodeError('');
    } else {
      setCodeError('Wrong code');
    }
  };

  const fetchSurveyData = useCallback(async () => {
    try {
      let allAssessmentData = [];

      for (const assessmentId of assessmentIds) {
        const response = await axios.get(`${BACKEND_URL}/survey`, {
          params: {
            currentAssessmentServerId: assessmentId,
            currentMonitoringServerId: monitoring,
            sandbox: sandbox,
          }
        });

        const { survey, name, type } = response.data;
        const updatedSurvey = survey.map(question => ({
          ...question,
          assessmentId,
          assessmentName: name,
          assessmentType: type
        }));

        allAssessmentData.push(...updatedSurvey);
      }

      setSurveyData(allAssessmentData);
    } catch (error) {
      console.error('Error fetching survey data:', error);
    }
  }, [assessmentIds, monitoring, sandbox]);

  useEffect(() => {
    if (codeValidated) {
      fetchSurveyData();
    }
  }, [codeValidated, fetchSurveyData]);

  useEffect(() => {
    if (surveyData.length > 0) {
      const newAssessmentId = surveyData[currentQuestionIndex]?.assessmentId;
      if (newAssessmentId !== currentAssessmentId) {
        setCurrentAssessmentId(newAssessmentId);
        setAssessmentName(surveyData[currentQuestionIndex].assessmentName);
        setResponseId(null);
      }
    }
  }, [currentQuestionIndex, surveyData, currentAssessmentId]);

  const currentQuestion = surveyData[currentQuestionIndex];
  const currentAssessmentType = currentQuestion?.assessmentType;

  const submitAnswer = async (questionId, answer, isMatrix = false) => {
    if (!currentAssessmentId) {
      console.error('No current assessment ID available.');
      return;
    }

    let updatedSurveyData;

    if (isMatrix) {
      const matrixResponseArray = Object.entries(answer).map(([key, value]) => `${key}: ${value}`);
      updatedSurveyData = surveyData.map(question => {
        if (question.questionId === questionId) {
          return {
            ...question,
            response: matrixResponseArray,
            linkingId: linkingCode
          };
        }
        return question;
      });
    } else if (currentQuestion.questionType === "checkbox") {
      updatedSurveyData = surveyData.map(question => {
        if (question.questionId === questionId) {
          return {
            ...question,
            response: answer.flat(),
            linkingId: linkingCode
          };
        }
        return question;
      });
    } else {
      updatedSurveyData = surveyData.map(question => {
        if (question.questionId === questionId) {
          return {
            ...question,
            response: [answer],
            linkingId: linkingCode
          };
        }
        return question;
      });
    }

    const filteredResponses = updatedSurveyData
      .filter(question => question.assessmentId === currentAssessmentId)
      .map(question => ({
        ...question,
        response: question.response,
        linkingId: question.linkingId
      }));

    const responseData = {
      userId: user,
      assessmentId: currentAssessmentId,
      monitoringId: monitoring,
      assessmentType: currentAssessmentType,
      survey: filteredResponses,
      sandbox: sandbox,
      displayName: displayName || 'Anonymous'
    };

    try {
      setLoading(true);
      if (!responseId) {
        const response = await axios.post(`${BACKEND_URL}/response`, responseData);
        console.log(response.data.success)
        if (response.data.success) {
          setResponseId(response.data.id);
          console.log(`New response created with ID ${response.data.id} for question ${questionId}`);
          return true; 
        }
      } else {
        const response = await axios.put(`${BACKEND_URL}/response`, {
          id: responseId,
          questionId: questionId,
          answer: isMatrix ? Object.entries(answer).map(([key, value]) => `${value}`) : answer,
          linkingId: linkingCode,
          sandbox: sandbox
        });
        if (response.data.success) {
          console.log(`Updated existing response with ID ${responseId} for question ${questionId}`);
          return true; 
        }
      }
    } catch (error) {
      console.error(`Error submitting answer for question ${questionId} in assessment ID ${currentAssessmentId}:`, error);
    } finally {
      setLoading(false); 
    }
    return false;
  };

  const getCurrentMatrixItem = () => {
    return currentQuestion.items[currentItemIndex];
  };

  const handleAnswerClick = async (questionId, answer) => {
    if (loading) return;

    let success;

    if (currentQuestion.questionType === "matrix-radio-ordered") {
      const currentItem = getCurrentMatrixItem();
      setMatrixResponses(prev => ({
        ...prev,
        [currentItem]: answer
      }));

      if (currentItemIndex < currentQuestion.items.length - 1) {
        setCurrentItemIndex(prevIndex => prevIndex + 1);
      } else {
        success = await submitAnswer(questionId, { ...matrixResponses, [currentItem]: answer }, true);
        if (success && currentQuestionIndex < surveyData.length - 1) {
          setCurrentItemIndex(0);
          setMatrixResponses({});
          setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        } else if (success) {
          setSurveyCompleted(true);
        }
      }
    } else {
      success = await submitAnswer(questionId, answer);
      if (success && currentQuestionIndex < surveyData.length - 1) {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      } else if (success) {
        setSurveyCompleted(true);
      }
    }
  };

  const handleTextSubmit = async (questionId) => {
    if (loading) return;

    const success = await submitAnswer(questionId, currentTextAnswer);
    if (success && currentQuestionIndex < surveyData.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else if (success) {
      setSurveyCompleted(true);
    }
    setCurrentTextAnswer('');
  };

  const handleCheckboxSubmit = async (questionId) => {
    if (loading) return;

    const success = await submitAnswer(questionId, selectedChoices);
    if (success && currentQuestionIndex < surveyData.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else if (success) {
      setSurveyCompleted(true);
    }
    setSelectedChoices([]);
  };

  const handleCheckboxSelection = (choice, event) => {
    event.currentTarget.blur();
    setSelectedChoices(prevChoices => {
      if (prevChoices.includes(choice)) {
        return prevChoices.filter(selected => selected !== choice);
      } else {
        return [...prevChoices, choice];
      }
    });
  };

  const handleSkipClick = () => {
    if (loading) return;

    if (currentQuestionIndex < surveyData.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      setSurveyCompleted(true);
    }
  };

  const handleBackClick = () => {
    if (loading) return;

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleRestartSurvey = () => {
    setCurrentQuestionIndex(0);
    setResponseId(null);
    setSurveyCompleted(false);
    setCurrentTextAnswer('');
    setSelectedChoices([]);
    setCurrentItemIndex(0);
    setMatrixResponses({});
    setNameSubmitted(false); 
    setDisplayName('');
    setLinkingIDValidated(false);
    setLinkingCode('');
    setIsCodeValid(false);
  };

  const handleGenerateCode = () => {
    const consonantPool = "BCDFGHJKLMNPRSTVWXYZ";
    const vowelPool = "AEIOU";
    const allChars = consonantPool + vowelPool;

    let code = "";
    while (code.split("").filter(c => consonantPool.includes(c)).length < 4 ||
           code.split("").filter(c => vowelPool.includes(c)).length < 4) {
      code = "";
      for (let i = 0; i < 8; i++) {
        code += allChars[Math.floor(Math.random() * allChars.length)];
      }
    }

    setLinkingCode(code.toUpperCase());
    setIsCodeValid(true);
  };

  const handleLinkingCodeChange = (e) => {
    const code = e.target.value.toUpperCase();
    setLinkingCode(code);
    setIsCodeValid(code.length > 0);
    setErrorMessage(code.length === 0 ? "Please enter a linking code." : '');
  };

  const handleSubmitName = (event) => {
    event.preventDefault();
    setNameSubmitted(true);
  };

  return (
    <>
      {!codeValidated ? (
        <Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
          <Box>
            <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
          </Box>
          <Typography variant="h3" mt="100px" mb="10px">Enter your code</Typography>
          <Typography variant="h6" mb="10px">The code is below the QR code in My Monitorings page</Typography>
          <TextField
            variant="outlined"
            value={authorizationCode}
            onChange={(e) => setAuthorizationCode(e.target.value.toUpperCase())}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                validateCode();
              }
            }}
            inputProps={{
              maxLength: 6,
              style: {
                letterSpacing: '0.5em',
                textAlign: 'center',
                fontSize: '2rem',
                textTransform: 'uppercase'
              }
            }}
            sx={{
              width: '300px',
              mb: 2,
              '& .MuiOutlinedInput-root': {
                backgroundImage: 'url("data:image/svg+xml,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"300\\" height=\\"24\\"><line x1=\\"0\\" y1=\\"20\\" x2=\\"100%\\" y2=\\"20\\" stroke=\\"#000\\" stroke-width=\\"2\\" stroke-dasharray=\\"6,6\\"/></svg>")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              },
              '& input': {
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: 'transparent'
              }
            }}
          />
          {codeError && (
            <Typography variant="body2" color="error" mb="10px">{codeError}</Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            onClick={validateCode}
            sx={{
              backgroundColor: '#F7941E',
              borderRadius: '50px',
              color: 'black',
              '&:hover': { backgroundColor: '#D17A1D' }
            }}
          >
            VALIDATE
          </Button>
        </Box>
      ) : !linkingIDValidated ? (
<Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
          <Box>
            <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
          </Box>
          <Typography variant="h3" mt="100px" mb="10px">Enter a linking code</Typography>
          <TextField
            variant="outlined"
            value={linkingCode}
            onChange={handleLinkingCodeChange}
            error={!!errorMessage}
            helperText={errorMessage}
            inputProps={{
              maxLength: 8,
              style: {
                letterSpacing: '0.5em',
                textAlign: 'center',
                fontSize: '2rem',
                textTransform: 'uppercase'
              }
            }}
            sx={{
              width: '300px',
              mb: 2,
              '& .MuiOutlinedInput-root': {
                backgroundImage: 'url("data:image/svg+xml,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"300\\" height=\\"24\\"><line x1=\\"0\\" y1=\\"20\\" x2=\\"100%\\" y2=\\"20\\" stroke=\\"#000\\" stroke-width=\\"2\\" stroke-dasharray=\\"6,6\\"/></svg>")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              },
              '& input': {
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: 'transparent'
              }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!isCodeValid}
            onClick={() => setLinkingIDValidated(true)}
            sx={{
              backgroundColor: '#F7941E',
              borderRadius: '50px',
              color: 'black',
              '&:hover': { backgroundColor: '#D17A1D' }
            }}
          >
            Validate Linking Code
          </Button>
          <Typography variant="body1" mt="20px" mb="10px">or</Typography>
          <Button
            type="submit"
            variant="contained"
            onClick={handleGenerateCode}
            sx={{
              backgroundColor: '#F7941E',
              borderRadius: '50px',
              color: 'black',
              '&:hover': { backgroundColor: '#D17A1D' }
            }}
          >
            Generate Linking Code
          </Button>
        </Box>
      ) : !nameSubmitted ? (
        <Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
          <Box>
            <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
          </Box>
          <Typography variant="h3" mt="50px" mb="20px">Enter the name (optional)</Typography>
          <form onSubmit={handleSubmitName} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <TextField
              variant="outlined"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              sx={{ width: '100%', maxWidth: '500px', mb: 2 }}
              inputProps={{
                maxLength: 30,
                style: {
                  letterSpacing: '0.5em',
                  textAlign: 'center',
                  fontSize: '2rem',
                  textTransform: 'uppercase'
                }
              }}
            />
            <Button 
              type="submit" 
              variant="contained"
              sx={{
                backgroundColor: '#F7941E',
                borderRadius: '50px',
                color: 'black',
                '&:hover': { backgroundColor: '#D17A1D' }
              }}
            >
              {displayName.trim() ? 'Submit' : 'Skip'}
            </Button>
          </form>
        </Box>
      ) : (
        <>
          {surveyCompleted ? (
            <Box m="40px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
              <Box>
                <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
              </Box>
              <Typography variant="h3" mt="100px" mb="20px">Survey Completed</Typography>
              <Button
                variant="contained"
                onClick={handleRestartSurvey}
                sx={{
                  backgroundColor: '#F7941E',
                  borderRadius: '50px',
                  color: 'black',
                  '&:hover': { backgroundColor: '#D17A1D' }
                }}
              >
                Register Another Copy ?
              </Button>
            </Box>
          ) : (
            <Box m="40px" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Box>
                <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
              </Box>
              <Typography variant="h3" m="20px 0" align="center">{assessmentName}</Typography>
              {currentQuestion && (
                <Box display="flex" flexDirection="column" alignItems="center" p={4} boxShadow={2} borderRadius="8px" sx={{ width: '100%', maxWidth: '500px', mx: 'auto' }}>
                  <Typography variant="h4" mb={2}>
                    {currentQuestion.question}
                  </Typography>
                  {currentQuestion.questionType === "checkbox" && (
                    <Typography variant="h5" mb={2} fontStyle="italic">
                      Several choices possible
                    </Typography>
                  )}
                  {(currentQuestion.questionType === "radio-unordered" || currentQuestion.questionType === "radio-ordered") && (
                    <Typography variant="h5" mb={2} fontStyle="italic">
                      Only one choice possible
                    </Typography>
                  )}
                  {currentQuestion.questionType === "matrix-radio-ordered" ? (
                    <>
                      <Typography variant="h5" mb={2} fontStyle="italic">
                        {getCurrentMatrixItem()}
                      </Typography>
                      <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" mb={2}>
                        {currentQuestion.choices.map((choice, index) => (
                          <Button
                            key={index}
                            variant="contained"
                            onClick={() => handleAnswerClick(currentQuestion.questionId, choice)}
                            disabled={loading}
                            sx={{
                              m: 1,
                              p: 2,
                              width: 'auto',
                              height: 'auto',
                              textTransform: 'none',
                              fontSize: '1rem',
                              borderRadius: '8px',
                              backgroundColor: '#D3D3D3',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: '#A9A9A9'
                              }
                            }}
                          >
                            {choice}
                          </Button>
                        ))}
                      </Box>
                    </>
                  ) : currentQuestion.questionType === "text" ? (
                    <>
                      <TextField
                        multiline
                        rows={6}
                        value={currentTextAnswer}
                        onChange={(e) => setCurrentTextAnswer(e.target.value)}
                        placeholder="Enter your response here..."
                        variant="outlined"
                        fullWidth
                        sx={{
                          width: '100%',
                          mb: 2,
                          backgroundColor: '#D3D3D3',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#A9A9A9'
                            },
                            '&:hover fieldset': {
                              borderColor: '#A9A9A9'
                            }
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        disabled={!currentTextAnswer.trim() || loading}
                        onClick={() => handleTextSubmit(currentQuestion.questionId)}
                        sx={{
                          backgroundColor: '#F7941E',
                          borderRadius: '50px',
                          mb: 2,
                          color: 'black',
                          '&:hover': { backgroundColor: '#D17A1D' }
                        }}
                      >
                        Confirm submission
                      </Button>
                    </>
                  ) : currentQuestion.questionType === "checkbox" ? (
                    <>
                      <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" mb={2}>
                        {currentQuestion.choices.map((choice, index) => (
                          <Button
                            key={`${choice}-${selectedChoices.includes(choice)}`}
                            variant="contained"
                            onClick={(e) => handleCheckboxSelection(choice, e)}
                            disabled={loading}
                            sx={{
                              m: 1,
                              p: 2,
                              width: 'auto',
                              height: 'auto',
                              textTransform: 'none',
                              fontSize: '1rem',
                              borderRadius: '8px',
                              backgroundColor: selectedChoices.includes(choice) ? '#A9A9A9' : '#D3D3D3',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: '#A9A9A9'
                              }
                            }}
                          >
                            {choice}
                          </Button>
                        ))}
                      </Box>
                      <Button
                        variant="contained"
                        disabled={selectedChoices.length === 0 || loading}
                        onClick={() => handleCheckboxSubmit(currentQuestion.questionId)}
                        sx={{
                          backgroundColor: '#F7941E',
                          borderRadius: '50px',
                          mb: 2,
                          color: 'black',
                          '&:hover': { backgroundColor: '#D17A1D' }
                        }}
                      >
                        Confirm submission
                      </Button>
                    </>
                  ) : (
                    <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" mb={2}>
                      {currentQuestion.choices.map((choice, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          onClick={() => handleAnswerClick(currentQuestion.questionId, choice)}
                          disabled={loading}
                          sx={{
                            m: 1,
                            p: 2,
                            width: 'auto',
                            height: 'auto',
                            textTransform: 'none',
                            fontSize: '1rem',
                            borderRadius: '8px',
                            backgroundColor: '#D3D3D3',
                            color: 'black',
                            '&:hover': {
                              backgroundColor: '#A9A9A9'
                            }
                          }}
                        >
                          {choice}
                        </Button>
                      ))}
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                    <Button
                      variant="contained"
                      onClick={handleBackClick}
                      disabled={currentQuestionIndex === 0 || loading}
                      sx={{
                        backgroundColor: '#F7941E',
                        borderRadius: '50px',
                        color: 'black',
                        '&:hover': { backgroundColor: '#D17A1D' }
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSkipClick}
                      disabled={loading}
                      sx={{
                        backgroundColor: '#F7941E',
                        borderRadius: '50px',
                        color: 'black',
                        '&:hover': { backgroundColor: '#D17A1D' }
                      }}
                    >
                      SKIP
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Reporting;