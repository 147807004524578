import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stepper, Step, StepLabel, Typography, Box, Button, Paper, TextField, useTheme, useMediaQuery } from '@mui/material';
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import dtc_model_details_en from "../../assets/medias/dtc_model_details_en.svg"; 
import dtc_model_en from "../../assets/medias/dtc_model_en.svg"; 
import models_en from "../../assets/medias/kirkpatrick_guskey_models_en.svg";
import monitorings_en from "../../assets/medias/monitorings_en.png";
import assessments_en from "../../assets/medias/assessments_en.png";
import qr_code_section_en from "../../assets/medias/qr_code_section_en.png";
import creating_content_assessment_ai_en from "../../assets/medias/creating_content_assessment_ai_en.png";
import monitor_section_en from "../../assets/medias/monitor-section_en.png";
import regulate_section_en from "../../assets/medias/regulate-section_en.png";
import tutorialTranslations from "../../assets/tutorialTranslations.json";
import { useLanguage } from '../../contexts/LanguageContext';

const Tutorial = () => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const { languageCode } = useLanguage();
    const steps = tutorialTranslations[languageCode].steps;


    const navigate = useNavigate();

    const designRef = useRef(null);
    const monitorRef = useRef(null);
    const regulateRef = useRef(null);
    const resourcesRef = useRef(null);
    const settingsRef = useRef(null);

    const contentRef = useRef(null);

    const handleNext = () => {
        setActiveStep((prevStep) => {
            const nextStep = prevStep + 1;
            if (contentRef.current) {
                contentRef.current.scrollTop = 0;
            }
            return nextStep;
        });
    };

    const handleBack = () => {
        setActiveStep((prevStep) => {
            const nextStep = Math.max(prevStep - 1, 0);
            if (contentRef.current) {
                contentRef.current.scrollTop = 0;
            }
            return nextStep;
        });
    };

    const handleStepClick = (stepIndex) => {
        setActiveStep(stepIndex);
        if (contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    };

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box component="section" >
                        <Typography variant={isMobile ? "h3" : "h2"} fontWeight="bold" mb={2}>
                            {tutorialTranslations[languageCode].content.gettingStarted.title}
                        </Typography>
                        <Typography variant={isMobile ? "h5" : "h4"}>
                            {tutorialTranslations[languageCode].content.gettingStarted.welcome} {tutorialTranslations[languageCode].content.gettingStarted.subtitle}
                        </Typography>
                        <Box display="flex" gap="10px" marginTop="20px">
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                color="black"
                                sx={{
                                    border: "1px solid rgb(226,227,229)",
                                    backgroundColor: "rgb(245,246,248)",
                                    borderRadius: "20px",
                                    padding: "10px",
                                }}
                                aria-label="explanation"
                            >
                                <ul style={{ fontSize: isMobile ? 14 : 18 }}>
                                    {tutorialTranslations[languageCode].content.gettingStarted.capabilities.map((capability, index) => (
                                        <li key={index}>{capability}</li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                );
            case 1:
            return (
                <Box component="section">
                    {/* Main Title */}
                    <Typography variant={isMobile ? "h3" : "h2"} fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.theory.title}
                    </Typography>

                    {/* Introduction Text */}
                    <Box mb={4}>
                        {tutorialTranslations[languageCode].content.theory.mainText.map((text, index) => (
                            <Typography 
                                key={index}
                                sx={{ fontSize: isMobile ? 14 : 18, mb: 2, textAlign: "justify" }}
                            >
                                {text}
                            </Typography>
                        ))}
                    </Box>

                    {/* First Image */}
                    <Box display="flex" flexDirection="column" alignItems="center" my={3}>
                        <img
                            alt="Training evaluation models"
                            src={models_en}
                            style={{
                                cursor: "pointer",
                                borderRadius: "1%",
                                width: "100%",
                                maxWidth: "800px",
                            }}
                        />
                    </Box>

                    {/* Middle Section Text */}
                    <Box mb={4}>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2, textAlign: "justify" }}>
                            {tutorialTranslations[languageCode].content.theory.neglectText}
                        </Typography>

                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2, textAlign: "justify" }}>
                            {tutorialTranslations[languageCode].content.theory.gapText}
                        </Typography>

                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2, textAlign: "justify" }}>
                            {tutorialTranslations[languageCode].content.theory.modelText}
                        </Typography>
                    </Box>

                    {/* Second Image */}
                    <Box display="flex" flexDirection="column" alignItems="center" my={3}>
                        <img
                            alt="Digital Training Companion model"
                            src={dtc_model_en}
                            style={{
                                cursor: "pointer",
                                borderRadius: "1%",
                                width: "100%",
                                maxWidth: "800px",
                            }}
                        />
                    </Box>

                    {/* Final Section Text */}
                    <Box mb={4}>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2, textAlign: "justify" }}>
                            {tutorialTranslations[languageCode].content.theory.finalText}
                        </Typography>
                    </Box>

                    {/* Final Image */}
                    <Box display="flex" flexDirection="column" alignItems="center" my={3}>
                        <img
                            alt="Digital Training Companion model details"
                            src={dtc_model_details_en}
                            style={{
                                cursor: "pointer",
                                borderRadius: "1%",
                                width: "100%",
                                maxWidth: "900px",
                            }}
                        />
                    </Box>
                </Box>
            );
case 2:
    return (
        <Box component="section">
            {/* Main Title */}
            <Typography variant={isMobile ? "h3" : "h2"} fontWeight="bold" mb={3}>
                {tutorialTranslations[languageCode].content.mainFunctionalities.title}
            </Typography>

            {/* Menu Sections */}
            <Typography variant={isMobile ? "h5" : "h4"} mb={2}>
                {tutorialTranslations[languageCode].content.mainFunctionalities.introduction}
            </Typography>

            <Box mb={3}>
                <ul style={{ fontSize: isMobile ? 14 : 18 }}>
                    <li>
                        <Box component="span" sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => scrollToSection(designRef)}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.title}
                        </Box>
                    </li>
                    <li>
                        <Box component="span" sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => scrollToSection(monitorRef)}>
                            
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.title}
                        </Box>
                    </li>
                    <li>
                        <Box component="span" sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => scrollToSection(regulateRef)}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.title}
                        </Box>
                    </li>
                    <li>
                        <Box component="span" sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => scrollToSection(resourcesRef)}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.resources.title}
                        </Box>
                    </li>
                    <li>
                        <Box component="span" sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => scrollToSection(settingsRef)}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.title}
                        </Box>
                    </li>
                </ul>
            </Box>

            {/* Design Section */}
            <Box component="section" ref={designRef}>
                <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.title}
                </Typography>

                <Box mb={3}>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.description1}{" "}
                        <Box
                            component="span"
                            onClick={() => window.open('/dashboard', '_blank')}
                            sx={{ cursor: 'pointer', color: 'blue', display: 'inline' }}
                        >
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.description2}
                        </Box>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.description3}
                    </Typography>

                    <Typography sx={{ fontSize: isMobile ? 14 : 18 }}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition1}
                    <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition2}</strong> 
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition3}
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition4}
                     <Box
                            component="span"
                            onClick={() => handleStepClick(1)}
                            sx={{ 
                                cursor: 'pointer', 
                                color: 'blue', 
                                display: 'inline',
                            }}
                        >
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition5}
                        </Box>
                   {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.monitoringDefinition6}
                    </Typography>
                </Box>

                {/* Monitorings Image */}
                <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                    <img
                        alt="Monitorings interface"
                        src={monitorings_en}
                        style={{
                            cursor: "pointer",
                            borderRadius: "1%",
                            width: "100%",
                            maxWidth: "1500px",
                        }}
                    />
                </Box>

                {/* Creating a Monitoring Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                   {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_monitoring.title}
                    </Typography>

                    <Box sx={{ fontSize: isMobile ? 14 : 18 }}>
                        <ol style={{ marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_monitoring.steps.map((step, index) => (
                            <li key={index}>
                            {typeof step === 'string' ? (
                                step
                            ) : (
                                <>
                                {step.text} <strong>{step.bold}</strong> {step.suffix}
                                </>
                            )}
                            </li>
                        ))}
                        </ol>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mt: 2 }}>
                              {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_monitoring.note}
                        </Typography>
                    </Box>
                </Box>

                {/* Managing Your Monitoring Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                              {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.title}
                    </Typography>

                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        <li>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.instructions}</li>
                        <li>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.actions.intro.prefix}{' '}
                        <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.actions.intro.bold}</strong>{' '}
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.actions.intro.suffix}
                        <Box component="ul" sx={{ marginLeft: '20px', marginTop: '5px' }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.actions.options.map((option, index) => (
                            <li key={index}>
                                <Box component="span" sx={{ color: 'red' }}>
                                <strong>{option.action}</strong>
                                </Box>
                                {' '}{option.description}
                            </li>
                            ))}
                        </Box>
                        </li>
                    </Box>

                    <Box sx={{ 
                        backgroundColor: '#FFF3E0', 
                        padding: '10px', 
                        borderRadius: '5px', 
                        mt: 2,
                        border: '1px solid #D32F2F' 
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16 }} color="error">
                            ⚠️   &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_monitoring.warning}
                        </Typography>
                    </Box>
                </Box>

                {/* Sharing a Monitoring Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                          {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_monitoring.title}
                    </Typography>

                    <Box component="ol" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_monitoring.steps.map((step, index) => (
                            <li key={index}>
                            {step.text}{' '}
                            {step.bold && <strong>{step.bold}</strong>}
                            {step.suffix && ` ${step.suffix}`}
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Importing a Monitoring Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.importing_monitoring.title}
                    </Typography>

                    <Box component="ol" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px', mb:2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.importing_monitoring.steps.map((step, index) => (
                            <li key={index}>
                            {step.text}{' '}
                            {step.bold && <strong>{step.bold}</strong>}
                            {step.suffix && ` ${step.suffix}`}
                            </li>
                        ))}
                    </Box>

                    <Box sx={{ 
                        backgroundColor: '#E3F2FD', 
                        padding: '10px', 
                        borderRadius: '5px', 
                        border: '1px solid #90CAF9'
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16, color: '#1976D2' }}>
                            💡 &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.importing_monitoring.roleNote.title}
                        </Typography>
                        <Box 
                            component="ul" 
                            sx={{ 
                                marginLeft: '20px', 
                                marginTop: '5px', 
                                marginBottom: '0',
                                fontSize: isMobile ? 13 : 16,
                                color: '#1976D2'
                            }}
                            >
                            {Object.entries(tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.importing_monitoring.roleNote.roles).map(([role, content]) => (
                                <li key={role}>
                                <strong>{content.mode}:</strong>
                                {' '}{content.description}
                                </li>
                            ))}
                        </Box>
                    </Box>
                </Box>

                {/* Assessment Information */}
                <Box mb={4}>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.assessmentDefinition.text}{' '}
                        <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.assessmentDefinition.bold}</strong>{' '}
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.assessmentDefinition.suffix}
                    </Typography>
                </Box>

                {/* Assessments Image */}
                <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                    <img
                        alt="Assessments interface"
                        src={assessments_en}
                        style={{
                            cursor: "pointer",
                            borderRadius: "1%",
                            width: "100%",
                            maxWidth: "1500px",
                        }}
                    />
                </Box>
                {/* Creating an Assessment Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.title}
                    </Typography>

                    <Box component="ol" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {/* Basic steps */}
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.basic.map((step, index) => (
                            <li key={index} style={{ marginBottom: '15px' }}>
                            {typeof step === 'string' ? (
                                step
                            ) : (
                                <>
                                {step.text} <strong>{step.bold}</strong>{step.suffix}
                                </>
                            )}
                            </li>
                        ))}

                        {/* Assessment types section */}
                        <li style={{ marginBottom: '15px' }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.assessmentTypes.title}
                            <Box component="ul" sx={{ marginLeft: '20px', marginTop: '15px' }}>
                            {Object.entries(tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.assessmentTypes.types).map(([key, type]) => (
                                <li key={key} style={{ marginBottom: '10px' }}>
                                <strong>{type.name}</strong>
                                {type.note && <> {type.note}</>}: {type.description}
                                </li>
                            ))}
                            </Box>
                        </li>

                        {/* Final step */}
                        <li>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.final.text}{' '}
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.final.bold}</strong>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating.steps.final.suffix}
                        </li>
                    </Box>
                </Box>

                {/* Managing Your Assessment Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.title}
                    </Typography>

                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {/* Editing session number */}
                        <li style={{ marginBottom: '15px' }}>
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.editing_session.title}:</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.editing_session.description}
                        </li>

                        {/* Editing assessment name */}
                        <li style={{ marginBottom: '15px' }}>
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.editing_name.title}:</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.editing_name.description}
                        </li>

                        {/* Changing status */}
                        <li style={{ marginBottom: '15px' }}>
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.changing_status.title}:</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.changing_status.description}{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.changing_status.statuses.map((status, index, array) => (
                                <span key={status}>
                                    <strong>{status}</strong>
                                    {index < array.length - 1 ? ', ' : ''}
                                </span>
                            ))}.
                        </li>

                        {/* Additional options */}
                        <li style={{ marginBottom: '15px' }}>
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.additions.title}:</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.additions.description}{' '}
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.additions.menuName}</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.additions.suffix}:
                            <Box component="ul" sx={{ marginLeft: '20px', marginTop: '10px' }}>
                                {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.additional_options.map((option, index) => (
                                    <li key={index} style={{ marginBottom: '10px' }}>
                                        <Box component="span" sx={{ color: 'red' }}>
                                            <strong>{option.text}</strong>
                                        </Box>{' '}
                                        {option.suffix}
                                    </li>
                                ))}
                            </Box>
                        </li>

                        {/* Sharing assessment */}
                        <li>
                            <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.sharing_assessment.title}:</strong>{' '}
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.steps.sharing_assessment.description}
                        </li>
                    </Box>

                    {/* Warning box */}
                    <Box sx={{ 
                        backgroundColor: '#FFF3E0', 
                        padding: '15px', 
                        borderRadius: '5px', 
                        mt: 3,
                        border: '1px solid #D32F2F' 
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16 }} color="error">
                            ⚠️   &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.managing_assessment.warning}
                        </Typography>
                    </Box>
                </Box>

                {/* Assessment Content Creation Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.title}
                    </Typography>

                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.draft_status}
                    </Typography>

                    {/* Types of Questions */}
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.kinds_assessments1}
                    </Typography>

                   <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px', mb: 3 }}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.types1.map((type, index, array) => (
                        <li 
                        key={index} 
                        style={{ marginBottom: index === array.length - 1 ? '0' : '15px' }}
                        >
                        <strong>{type.title}:</strong> {type.description}
                        </li>
                    ))}
                    </Box>

                    {/* Warning Box */}
                    <Box sx={{ 
                        backgroundColor: '#FFF3E0', 
                        padding: '15px', 
                        borderRadius: '5px', 
                        mb: 3,
                        border: '1px solid #D32F2F' 
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16 }} color="error">
                            ⚠️   &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.warning1}

                        </Typography>
                    </Box>

                    {/* Learning Types */}
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.kinds_assessments2}
                    </Typography>

                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px', mb: 3 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.types2.map((type, index, array) => (
                            <li 
                            key={index} 
                            style={{ marginBottom: index === array.length - 1 ? '0' : '15px' }}
                            >
                            <strong>{type.title}</strong>
                            {type.description && <>: {type.description}</>}
                            </li>
                        ))}
                        </Box>

                    {/* Info Box */}
                    <Box sx={{ 
                        backgroundColor: '#E3F2FD', 
                        padding: '10px', 
                        borderRadius: '5px', 
                        mb: 3,
                        border: '1px solid #90CAF9'
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16, color: '#1976D2' }}>
                            💡   &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.reminder}
                        </Typography>
                    </Box>

                    {/* Important Note */}
                    <Box sx={{ 
                        backgroundColor: '#FFF3E0', 
                        padding: '15px', 
                        borderRadius: '5px', 
                        mb: 3,
                        border: '1px solid #D32F2F'  
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16 }} color="error">
                            ⚠️   &ensp; {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.warning2}

                        </Typography>
                    </Box>

                    {/* AI Creation Section */}
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 3 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.creating_assessment_content.ia_creation}
                    </Typography>

                    {/* AI Assessment Image */}
                    <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                        <img
                            alt="AI assessment creation"
                            src={creating_content_assessment_ai_en}
                            style={{
                                cursor: "pointer",
                                borderRadius: "1%",
                                width: "100%",
                                maxWidth: "1500px",
                            }}
                        />
                    </Box>

                    {/* Sharing Space Section */}
                    <Box mb={4}>
                        <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment.title}
                        </Typography>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 3 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment.description1}{' '}
                        <strong>{tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment.description2}</strong>{' '}
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment.description3}
                        </Typography>

                        {/* QR Code Image */}
                        <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                            <img
                                alt="QR code section"
                                src={qr_code_section_en}
                                style={{
                                    cursor: "pointer",
                                    borderRadius: "1%",
                                    width: "30%",
                                    maxWidth: "1500px",
                                }}
                            />
                        </Box>
                        {/* Sharing Space Section */}
                        <Box>
                            <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                                 {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment.QR_code}
                            </Typography>
                            <Typography component="div" sx={{ fontSize: isMobile ? 14 : 18, mb: 2, pl: 2 }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.design.sharing_assessment. QR_code_options.map((option, index) => (
                                <React.Fragment key={index}>
                                • {option}<br/>
                                </React.Fragment>
                            ))}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Monitor Section */}
            <Box component="section" mb={4} ref={monitorRef}>
                <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.title}
                </Typography>

                <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 3 }}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.description}
                </Typography>

                {/* Monitor Image */}
                <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                    <img
                        alt="Monitor interface"
                        src={monitor_section_en}
                        style={{
                            cursor: "pointer",
                            borderRadius: "1%",
                            width: "100%",
                            maxWidth: "1500px",
                        }}
                    />
                </Box>

                {/* Data Selection Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.dataSelection.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        At the top of the page, you'll find two dropdown menus:
                    </Typography>
                        <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.dataSelection.options.map((option, index) => (
                            <li key={index}>
                            <strong>{option.title}:</strong> {option.description}
                            </li>
                        ))}
                        </Box>
                </Box>

                {/* Results Dashboard Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.results_dashboard.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.results_dashboard.description}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.results_dashboard.types.map((type, index) => (
                            <li key={index}>{type}</li>
                        ))}
                    </Box>
                </Box>

                {/* Data Visualization Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.data_visualization.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.data_visualization.description}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.data_visualization.types.map((type, index) => (
                            <li key={index}>
                            <strong>{type.title}:</strong> {type.description}
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Additional Features Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.additional_features.title}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.additional_features.features.map((feature, index) => (
                            <li key={index} style={{ marginBottom: feature.items ? '0' : '10px' }}>
                            <strong>{feature.title}:</strong>{' '}
                            {feature.description && feature.description}
                            {feature.items && (
                                <Box component="ul" sx={{ marginLeft: '20px', marginTop: '5px' }}>
                                {feature.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                ))}
                                </Box>
                            )}
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Export Features Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.export_features.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.export_features.description}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.export_features.options.map((option, index) => (
                            <li key={index}>
                            <strong>{option.title}:</strong> {option.description}
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Best Practices Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.bestPractices.title}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.bestPractices.items.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </Box>
                </Box>

                {/* Info Box */}
                <Box sx={{ 
                    backgroundColor: '#E3F2FD', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    border: '1px solid #90CAF9'
                }}>
                    <Typography sx={{ fontSize: isMobile ? 13 : 16, color: '#1976D2' }}>
                        💡   &ensp;  {tutorialTranslations[languageCode].content.mainFunctionalities.sections.monitor.reminder}
                    </Typography>
                </Box>
            </Box>

            {/* Regulate Section */}
            <Box component="section" mb={4} ref={regulateRef}>
                <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
                    {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.title}
                </Typography>

                <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 3 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.description}
                </Typography>

                {/* Monitor Image */}
                <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                    <img
                        alt="Regulate interface"
                        src={regulate_section_en}
                        style={{
                            cursor: "pointer",
                            borderRadius: "1%",
                            width: "100%",
                            maxWidth: "1500px",
                        }}
                    />
                </Box>

                {/* Creating Log Entries Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.creating_logs.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.creating_logs.addLogs}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.creating_logs.infos.map((info, index) => (
                            <li key={index}>
                            <strong>{info.title}:</strong> {info.description}
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Log Types Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.logTypes.title}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {Object.entries(tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.logTypes.types).map(([key, type]) => (
                            <li key={key} style={{ marginBottom: '10px' }}>
                                <strong>{type.title}:</strong> {type.description}
                                <Box component="ul" sx={{ marginLeft: '20px', marginTop: '5px' }}>
                                    {type.examples.map((example, index) => (
                                        <li key={index}>{example}</li>
                                    ))}
                                </Box>
                            </li>
                        ))}
                    </Box>
                </Box>

                {/* Training Activity Timeline */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.timeline.title}
                    </Typography>
                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.timeline.description}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.timeline.items.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </Box>
                </Box>

                {/* Best Practices Section */}
                <Box mb={4}>
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.bestPractices.title}
                    </Typography>
                    <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.bestPractices.items.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </Box>
                </Box>

                {/* Info Box */}
                <Box sx={{ 
                    backgroundColor: '#E3F2FD', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    border: '1px solid #90CAF9'
                }}>
                    <Typography sx={{ fontSize: isMobile ? 13 : 16, color: '#1976D2'}}>
                       💡   &ensp;  {tutorialTranslations[languageCode].content.mainFunctionalities.sections.regulate.reminder}
                    </Typography>
                </Box>

                {/* Settings Section */}
                <Box component="section" mb={4} mt={4} ref={settingsRef}>
                    <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.title}
                    </Typography>

                    <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 3 }}>
                        {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.description}
                    </Typography>

                    {/* User Information Section */}
                    <Box mb={4}>
                        <Typography variant="h4" fontWeight="bold" mb={2}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.userInfo.title}
                        </Typography>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.userInfo.description}
                        </Typography>
                        <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.userInfo.items.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </Box>
                    </Box>

                    {/* Platform Information Section */}
                    <Box mb={4}>
                        <Typography variant="h4" fontWeight="bold" mb={2}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.platformInfo.title}
                        </Typography>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.platformInfo.description}
                        </Typography>
                        <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.platformInfo.items.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </Box>
                    </Box>

                    {/* Account Management Section */}
                    <Box mb={4}>
                        <Typography variant="h4" fontWeight="bold" mb={2}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.accountManagement.title}
                        </Typography>
                        <Typography sx={{ fontSize: isMobile ? 14 : 18, mb: 2 }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.accountManagement.description}
                        </Typography>
                        <Box component="ul" sx={{ fontSize: isMobile ? 14 : 18, marginLeft: '20px' }}>
                            {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.accountManagement.notes.map((note, index) => (
                                <li key={index}>{note}</li>
                            ))}
                        </Box>
                    </Box>

                    {/* Warning Box */}
                    <Box sx={{ 
                        backgroundColor: '#FFF3E0', 
                        padding: '15px', 
                        borderRadius: '5px', 
                        mb: 4,
                        border: '1px solid #D32F2F' 
                    }}>
                        <Typography sx={{ fontSize: isMobile ? 13 : 16 }} color="error">
                            ⚠️   &ensp;  {tutorialTranslations[languageCode].content.mainFunctionalities.sections.settings.accountManagement.warning}
                        </Typography>
                            </Box>
                        </Box>
                    </Box>
                 </Box>
             );


            case 3:
                return (
                    <>
                        <Typography variant={isMobile ? "h3" : "h2"} fontWeight="bold" mt={3} mb={2}>
                            Your first Training Monitoring 
                        </Typography>
                        <Typography style={{ fontSize: isMobile ? 14 : 18, fontStyle: 'italic', color: '#666' }}>
                            Content for this section will be available soon. Stay tuned for updates!
                        </Typography>
                    </>
                );
            case 4:
                return (
                    <>
                        <Typography variant={isMobile ? "h3" : "h2"} fontWeight="bold" mt={3} mb={2}>
                            A More Advanced Monitoring
                        </Typography>
                        <Typography style={{ fontSize: isMobile ? 14 : 18, fontStyle: 'italic', color: '#666' }}>
                            Content for this section will be available soon. Stay tuned for updates!
                        </Typography>
                    </>
                );
            default:
                return <Typography>Unknown Step</Typography>;
        }
    };

    return (
        <Box display="flex" height="100vh">
            {!isMobile && <Sidebar />}
            <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
                <Box mt="10px" ml="10px">
                    <Topbar title="Tutorial" />
                </Box>

                <Box 
                    ref={contentRef}
                    flexGrow={1} 
                    overflow="auto" 
                    m={isMobile ? "10px" : "20px"}
                    sx={{
                        '&::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,.1)',
                            outline: '1px solid slategrey'
                        }
                    }}
                >
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            padding: isMobile ? '10px' : '20px', 
                            paddingBottom: isMobile ? '30px' : '40px',  
                            borderRadius: '10px',
                            marginBottom: '20px' 
                        }}
                    >
                        <Stepper activeStep={activeStep} alternativeLabel orientation={isMobile ? 'vertical' : 'horizontal'}>
                            {steps.map((label, index) => (
                                <Step key={index} active={activeStep === index} onClick={() => handleStepClick(index)}>
                                    <StepLabel
                                        sx={{
                                            cursor: 'pointer',
                                            '& .MuiStepIcon-root': {
                                                color: activeStep === index ? '#F7941D' : '#D3D3D3',
                                            }
                                        }}
                                    >
                                        {isMobile ? <Typography variant="caption">{label}</Typography> : label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Box mt="20px">
                            {renderStepContent(activeStep)}
                        </Box>

                        <Box mt="20px" display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between">
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="contained"
                                fullWidth={isMobile}
                                sx={{ 
                                    backgroundColor: '#F7941E',
                                    borderRadius: '50px',
                                    color: 'black',
                                    '&:hover': { backgroundColor: '#D17A1D' },
                                    mb: isMobile ? 2 : 0
                                }}
                            >
                                {tutorialTranslations[languageCode].buttons.back}
                            </Button>

                            <Button
                                onClick={activeStep === steps.length - 1 ? handleBack : handleNext}
                                variant="contained"
                                fullWidth={isMobile}
                                sx={{ 
                                    backgroundColor: '#F7941E',
                                    borderRadius: '50px',
                                    color: 'black',
                                    '&:hover': { backgroundColor: '#D17A1D' }
                                }}
                            >
                                {activeStep === steps.length - 1 ? tutorialTranslations[languageCode].buttons.startAgain : tutorialTranslations[languageCode].buttons.next}
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default Tutorial;