import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { useAuthUser } from '../contexts/AuthUserContext';

const DisplayTextQuestion = ({ title, content, displayName, comment = false }) => {

    const { currentUser } = useAuthUser();

    return (
        <Box bgcolor="background.paper" m={2} p={2}>
            <Box display="flex" justifyContent="space-between">
                <Typography 
                    color="rgb(102,102,102)" 
                    variant="h5" 
                    fontWeight="bold" 
                    component="div" 
                    gutterBottom
                    sx={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word'
                    }}
                >
                    {title}
                </Typography>
                {comment && <CommentIcon/>}
            </Box>
            
            <Divider />
            <Box mt={2}>
                {content.map((item, index) => (
                    <Typography key={index} 
                        variant="h6" 
                        component="div" 
                        gutterBottom
                        sx={{
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word'
                        }}
                    >
                        {displayName[index]}: {item}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default DisplayTextQuestion;
