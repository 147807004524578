import React, { useState, useEffect } from 'react';
import { InputLabel, Box, MenuItem, FormControl, Button } from "@mui/material";
import { CircularProgress } from '@mui/material';
import Select from '@mui/material/Select';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import saveAs from 'file-saver';
import PDFlogo from '../../assets/medias/pdf.png';
import DOClogo from '../../assets/medias/doc.png';
import CSVlogo from '../../assets/medias/csv.png';

import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import AssessmentTabResult from '../../components/AssessmentTabResult';
import AssessmentTabResultWithFilter from '../../components/AssessmentTabResultWithFilter';
import { buttonStyle } from '../../components/styledComponents'
import { useMessageService } from '../../services/MessageService';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { transformAssessments, formatLatestDate } from "../../utils/ObjectsUtils";
import { prepareChartData, prepareCommentData } from "../../utils/ChartDataUtils";
import { AssessmentType, UserType } from "../../utils/enums";
import { BACKEND_URL } from "../../config";
import { useLanguage } from '../../contexts/LanguageContext';

const Reports = () => {

    const [monitorings, setMonitorings] = useState([]);
    const [selectedMonitoring, setSelectedMonitoring] = useState('');
    const [selectedMonitoringId, setSelectedMonitoringId] = useState('');
    const [assessments, setAssessments] = useState([]);
    const [currentUserId, setCurrentUserId] = useState('');
    const { languageCode } = useLanguage();

    const [selectedUser, setSelectedUser] = useState('');
    const [allEmails, setAllEmails] = useState('');

    const [days, setDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedDayAssessments, setSelectedDayAssessments] = useState([]);

    const [totalAssessments, setTotalAssessments] = useState(0)
    const [latestResponseDate, setLatestResponseDate] = useState('');

    const [valuesTabOne, setValuesTabOne] = useState(0);
    const [valuesTabTwo, setValuesTabTwo] = useState(0);
    const [valuesTabThree, setValuesTabThree] = useState(0);
    const [valuesTabFour, setValuesTabFour] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [isExportingPDF, setIsExportingPDF] = useState(false);
    const [isExportingDOCX, setIsExportingDOCX] = useState(false);

    // for i18n
    const { getMessage } = useMessageService();
    const { currentUser } = useAuthUser();

    // DEBUG
    useEffect(() => {

        console.log("Assessments for this monitoring", assessments);
        console.log("days", days);

    }, [assessments, days]);


    // fetch the assessments for a the selected monitoring
    useEffect(() => {

        if (selectedMonitoring){
            console.log("fetching for:", selectedMonitoring._id);

            const fetchAssessments = async () => {
    
                const token = localStorage.getItem('token');
                if (!token) {
                    console.log('No token found');
                    return;
                }
        
                try {
                    const response = await axios.get(`${BACKEND_URL}/assessments/${selectedMonitoring._id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
        
                    if (response.data && response.data.length > 0) {
                        const transformedAssessments = transformAssessments(response.data);
                        // Spread transformed assessments into the main assessments array
                        setAssessments(transformedAssessments);

                        // extract the days from the assessments
                        const days = transformedAssessments.map(assessment => assessment.day);

                        // convert to a set to remove duplicates, then convert back to array
                        setDays(Array.from(new Set(days)));
                    }

                } catch (error) {
                    console.error('Failed to fetch assessments:', error);
                }
            };

            fetchAssessments();
        }
    }, [selectedMonitoring]);


    useEffect(() => {

        if (selectedDay && selectedMonitoring){
            filterAssessmentsByDay();
        }
    }, [selectedDay]);

    // fetch all the monitorings of the current user when the page loads
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.log('No token found');
            return;
        }

        const decodedToken = jwt_decode(token);
        setCurrentUserId(decodedToken._id);

        const fetchMonitorings = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/monitorings/${decodedToken._id}`, {
                    headers: {Authorization: `Bearer ${token}`}
                });

                setMonitorings(response.data.monitorings);
            } catch (error) {
                console.error('Failed to fetch monitorings:', error);
            }
        };

        fetchMonitorings();
    }, [currentUserId]);

    const handleChangeTabsOne = (event, newValue) => {
        setValuesTabOne(newValue);
    };

    const handleChangeTabsTwo = (event, newValue) => {
        setValuesTabTwo(newValue);
    };

    const handleChangeTabsThree = (event, newValue) => {
        setValuesTabThree(newValue);
    };

    const handleChangeTabsFour = (event, newValue) => {
        setValuesTabFour(newValue);
    };

    const filterAssessmentsByDay = async () => {

        if (!assessments) return;

       let filteredAssessments = assessments;

        // If the current user is a Teacher, filter assessments by type and user
        if (currentUser.userStatus === 'Teacher') {
            filteredAssessments = filteredAssessments.filter(assessment =>
                assessment.type === 'Student characteristics' || assessment.type === 'Student learning outcomes'
            );
        }

        // Further filter assessments based on the selected day
        filteredAssessments = filteredAssessments.filter(assessment =>
            assessment.day === selectedDay
        );

        // Create an array with essential details from filtered assessments
        const assessmentsInfo = filteredAssessments.map(({ _id, type, name }) => ({ _id, type, name }));

        // Fetch and attach responses for each assessment
        const assessmentsWithResponses = await Promise.all(assessmentsInfo.map(async ({ _id, type, name }) => {
            let responses;
        
            // Check the user type and call the appropriate function
            if (currentUser.userStatus === UserType.TEACHER_TRAINER) {
                responses = await fetchResponsesByAssessment(_id);
            } else if (currentUser.userStatus === UserType.TEACHER) {
                responses = await fetchResponsesByAssessmentAndUserId(_id, currentUser._id);
            } else {
                // Handle other types or default case
                responses = []; // Or some other default behavior
            }
        
            // Return the assessment with the fetched responses
            return {
                name,
                type,
                responses: responses || []
            };
        }));

        // get all emails
        let allEmails = new Set();

        assessmentsWithResponses.forEach(assessment => {
            assessment.responses.forEach(response => {
                allEmails.add(response.email);
            });
        });

        let uniqueUsers = Array.from(allEmails);
        setAllEmails(uniqueUsers);

        setSelectedDayAssessments(assessmentsWithResponses);
        setTotalAssessments(assessmentsWithResponses.length);

        // Prepare and set chart and comment data
        const chartDataByType = handlePrepareChartData(assessmentsWithResponses);
        const commentDataByType = handlePrepareCommentData(assessmentsWithResponses);
        setChartData(chartDataByType);
        setCommentData(commentDataByType);

        // Calculate and set the latest response date with formatting
        const allResponses = assessmentsWithResponses.flatMap(assessment => assessment.responses);
        const latestDate = getLatestResponseDate(allResponses);

        if (latestDate) {
            setLatestResponseDate(formatLatestDate(latestDate));
        } else {
            setLatestResponseDate(null);
        }
    }

    /**
     * Handles the change of the selected user.
     * @param {Event} event - The event object from the user selection input.
    */
    const handleChangeUser = (event) => {

        let selectedUser = event.target.value;
        setSelectedUser(selectedUser);

        
        // Filter the response by removing the userId not selected
        const filteredSelectedDayAssessments = (selectedDayAssessments, currentEmail) => {
            return selectedDayAssessments.map(item => {

                if (item.type === AssessmentType.STUDENT_CHARACTERISTICS || item.type === AssessmentType.STUDENT_LEARNING_OUTCOMES) {

                    // If no user is selected (currentEmail is empty), include all responses
                    const filteredResponses = currentEmail ? 
                        item.responses.filter(response => response.email === currentEmail) :
                        item.responses;
                    
                    // Create a new object with the same properties as the original item
                    // but with filtered responses
                    return {
                        ...item,
                        responses: filteredResponses
                    };
                }
                
                return item;
            });
        };
    
        // Call the function with selectedDayAssessments and the selected userId
        const filteredAssessments = filteredSelectedDayAssessments(selectedDayAssessments, event.target.value);
    
        // Prepare and set chart and comment data
        const chartDataByType = handlePrepareChartData(filteredAssessments);
        const commentDataByType = handlePrepareCommentData(filteredAssessments);
        setChartData(chartDataByType);
        setCommentData(commentDataByType);
    
        // Calculate and set the latest response date with formatting
        const allResponses = filteredAssessments.flatMap(assessment => assessment.responses);
        const latestDate = getLatestResponseDate(allResponses);
    
        if (latestDate) {
            setLatestResponseDate(formatLatestDate(latestDate));
        } else {
            setLatestResponseDate(null);
        }
    };

    /**
     * Handles the change of the selected day for assessments.
     * @param {Event} event - The event object from the day selection input.
    */
    const handleChangeDay = (event) => {
        setSelectedDay(event.target.value);
    };

    /**
     * Handle the selection change of a monitoring
    */
    const handleChangeMonitoring = (event) => {
        setSelectedMonitoring(monitorings.find(monitoring => monitoring._id === event.target.value));
        setSelectedMonitoringId(event.target.value);

        // Reset the form and assessments related state
        setValuesTabOne(0);
        setValuesTabTwo(0);
        setValuesTabThree(0);
        setValuesTabFour(0);
        setAssessments([]);
        setDays([]);
        setSelectedDay('');
        setSelectedDayAssessments([]);
        setTotalAssessments(0);
        setLatestResponseDate('');
        setChartData([]);
        setCommentData([]);
    };

    /**
     * Retrieves all responses for a specific assessment by its ID.
     * @param {string} assessmentId - The unique identifier of the assessment.
     * @returns {Object[]} - The array of response objects returned from the server.
     */
    const fetchResponsesByAssessment = async (assessmentId) => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(`${BACKEND_URL}/responses/assessment/${assessmentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching responses: ${error}`);
        }
    }

    /**
     * Retrieves all responses for a specific assessment by its ID and a specific userId.
     * @param {string} _id - The unique identifier of the assessment.
     * @returns {Object[]} - The array of response objects returned from the server.
     */
    const fetchResponsesByAssessmentAndUserId = async (assessmentId, userId) => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(`${BACKEND_URL}/responses/assessment/${assessmentId}/userId/${userId}`, {

                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching responses: ${error}`);
        }
    }

    /**
     * Determines the most recent response date from an array of responses.
     * @param {Array} responses - The array of response objects, each containing a 'date' field.
     * @return {Date|null} - The latest date found in the responses, or null if no responses are provided.
    */
    const getLatestResponseDate = (responses) => {

        if (responses.length === 0) return null;

        let latest = new Date(responses[responses.length-1].completionDate); // Assuming each response object has a "date" field.
        responses.forEach(response => {
            const responseDate = new Date(response.date);
            if (responseDate > latest) latest = responseDate;
        });

        return latest;
    }

    /**
     * Converts an array of assessment data into a CSV format string.
     * Includes fixed headers for general assessment information, question details, and response data.
     * The function handles various question types and includes a column for proposed choices.
     * It also includes detailed logging for debugging purposes.
     * 
     * @param {Array} data - The data to be converted to CSV format. Each item in the array should be an object
     *                       representing a single response to a question, with properties matching the headers.
     * @returns {string} A string formatted as CSV content, with headers and data rows separated by newlines.
     *                   Returns an empty string if the input data is invalid.
     */
    const convertToCSV = (data) => {
        const escapeCSVValue = (value) => {
            if (value == null) return '';
            value = value.toString();
            if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"')) {
                return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
        };

        if (!data || !Array.isArray(data) || data.length === 0) {
            console.error('convertToCSV: Invalid data array');
            return '';
        }

        const headers = [
            "Assessment Name", "Assessment Type", "Linking ID", 
            "Display Name", "Completion Date", "Question Short Name", "Workshop", 
            "Question Type", "Question Item", "Proposed Choices", "Response", "Correct Answer"
        ];

        console.log('Headers:', headers);
        console.log('First data item:', data[0]);

        const csvContent = [
            headers.join(','),
            ...data.map(item => {
                const row = headers.map(header => {
                    // Convert header to camelCase for object key matching
                    const key = header.toLowerCase().split(' ').map((word, index) => 
                        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
                    ).join('');
                    const value = item[key];
                    console.log(`Field: ${key}, Value: ${value}`);
                    return escapeCSVValue(value);
                });
                console.log('Row:', row);
                return row.join(',');
            })
        ];

        console.log('CSV Content (first 3 lines):', csvContent.slice(0, 3));

        return csvContent.join('\n');
    };

    /**
     * Exports assessment data as a single CSV file in long format.
     * This function handles the entire export process, including data conversion, CSV creation, and file download.
     * 
     * The process includes the following steps:
     * 1. Converts selected day assessments to a long format.
     * 2. Converts the long format data to CSV.
     * 3. Creates a Blob with the CSV data.
     * 4. Initiates a download of the CSV file.
     * @throws {Error} If there's an issue during the export process, it will be caught and logged.
     */
    const handleExportData = () => {
        console.log('Starting export process...');
        console.log('Selected day assessments:', selectedDayAssessments);

        try {
            // Convert the data to long format
            const longFormatData = convertToLongFormat(selectedDayAssessments);

            if (longFormatData.length === 0) {
                console.error('No data to export after conversion to long format');
                // You might want to show an error message to the user here
                return;
            }

            // Convert the long format data to CSV
            const csvData = convertToCSV(longFormatData);

            // Create a Blob with the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

            // Create a link element, use it to download the csv file
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "raw_data.csv");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log('CSV file download initiated');
            } else {
                console.error('Browser does not support downloading');
            }
        } catch (error) {
            console.error('Error in export process:', error);
        }
    };


   /**
     * Converts a nested structure of assessment data into a flat, long format array.
     * This function processes assessment data, including responses and survey items,
     * and transforms it into a format suitable for CSV export.
     *
     * @param {Array} data - An array of assessment objects. Each assessment object should contain:
     *   - name: string (assessment name)
     *   - type: string (assessment type)
     *   - responses: Array of response objects, each containing:
     *     - linkingId: string
     *     - displayName: string
     *     - completionDate: string (ISO date string)
     *     - survey: Array of survey item objects, each containing:
     *       - shortName: string
     *       - workshop: string (optional)
     *       - questionType: string
     *       - question: string
     *       - items: Array (for matrix questions)
     *       - choices: Array or Object of proposed choices
     *       - response: string or Array
     *       - correctAnswer: string or Array (optional)
     *
     * @returns {Array} A flat array where each item represents a single response to a question,
     *                  including all relevant metadata. Returns an empty array if input is invalid.
     */
    const convertToLongFormat = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
            console.error('Invalid or empty data array passed to convertToLongFormat');
            return [];
        }

        return data.flatMap(assessment => {
            if (!assessment || !Array.isArray(assessment.responses)) {
                console.warn('Invalid assessment object:', assessment);
                return [];
            }

            return assessment.responses.flatMap(response => {
                if (!response || !Array.isArray(response.survey)) {
                    console.warn('Invalid response object:', response);
                    return [];
                }

                return response.survey.flatMap(surveyItem => {
                    if (!surveyItem) {
                        console.warn('Invalid survey item:', surveyItem);
                        return [];
                    }

                    console.log("linkingId",response.linkingId)

                    const baseData = {
                        assessmentName: assessment.name || '',
                        assessmentType: assessment.type || '',
                        linkingId: surveyItem.linkingId || '',
                        displayName: response.displayName || '',
                        completionDate: response.completionDate ? new Date(response.completionDate).toISOString().split('T')[0] : '',
                        questionShortName: surveyItem.shortName || '',
                        workshop: surveyItem.workshop || '',
                        questionType: surveyItem.questionType || '',
                    };

                    const getProposedChoices = (choices) => {
                        if (Array.isArray(choices)) {
                            return choices.join(';');
                        } else if (typeof choices === 'object') {
                            return Object.values(choices).join(';');
                        }
                        return '';
                    };

                    if (surveyItem.questionType === 'matrix-radio-ordered' && Array.isArray(surveyItem.items)) {
                        return surveyItem.items.map((item, index) => ({
                            ...baseData,
                            questionItem: item || '',
                            proposedChoices: getProposedChoices(surveyItem.choices),
                            response: Array.isArray(surveyItem.response) ? (surveyItem.response[index] || '') : '',
                            correctAnswer: Array.isArray(surveyItem.correctAnswer) ? (surveyItem.correctAnswer[index] || '') : ''
                        }));
                    } else if (surveyItem.questionType === 'checkbox' || surveyItem.questionType === 'radio-unordered') {
                        return [{
                            ...baseData,
                            questionItem: surveyItem.question || '',
                            proposedChoices: getProposedChoices(surveyItem.choices),
                            response: Array.isArray(surveyItem.response) ? surveyItem.response.join(';') : (surveyItem.response || ''),
                            correctAnswer: Array.isArray(surveyItem.correctAnswer) ? surveyItem.correctAnswer.join(';') : (surveyItem.correctAnswer || '')
                        }];
                    } else {
                        return [{
                            ...baseData,
                            questionItem: surveyItem.question || '',
                            proposedChoices: getProposedChoices(surveyItem.choices),
                            response: Array.isArray(surveyItem.response) ? surveyItem.response.join(";") : (surveyItem.response || ''),
                            correctAnswer: surveyItem.correctAnswer || ''
                        }];
                    }
                });
            });
        });
    };

    /**
     * Initiates the export of a PDF report based on a selected monitoring and day. It sends a request to the server to generate the PDF, then downloads it.
     * This function assumes the presence of a selected monitoring and day; it checks and aborts if either is missing.
     * @throws {Error} If the PDF export fails, it logs the error.
     */
    const handleExportPdfReport = async () => {
        try {
            setIsExportingPDF(true);

            // Check if a monitoring is selected
            if (!selectedMonitoring || !selectedDay) {
                console.error('No monitoring selected');
                return;
            }
            const token = localStorage.getItem("token");

            // Send a POST request to your backend endpoint
            const response = await axios.post(`${BACKEND_URL}/export/pdf`, 
                { 
                    monitoring: selectedMonitoring, 
                    selectedDay: selectedDay,
                    status: currentUser.userStatus,
                    userId: currentUser._id,
                    language: languageCode 
                }, 
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: 'application/pdf' });

            // Use file-saver to save the file on the client's machine
            saveAs(file, 'report.pdf');
        } catch (error) {
            console.error('Error exporting PDF:', error);
        } finally {
            setIsExportingPDF(false);
        }
    };

    /**
     * Initiates the export of a DOCX report based on a selected monitoring and day. It sends a request to the server to generate the DOCX, then downloads it.
     * This function assumes the presence of a selected monitoring and day; it checks and aborts if either is missing.
     * @throws {Error} If the DOCX export fails, it logs the error.
     */
    const handleExportDocxReport = async () => {
        try {
            setIsExportingDOCX(true);

            // Check if a monitoring is selected
            if (!selectedMonitoring || !selectedDay) {
                console.error('No monitoring selected');
                return;
            }
            const token = localStorage.getItem("token");

            // Send a POST request to your backend endpoint
            const response = await axios.post(`${BACKEND_URL}/export/docx`, 
                { 
                    monitoring: selectedMonitoring, 
                    selectedDay: selectedDay,
                    status: currentUser.userStatus,
                    userId: currentUser._id,
                    language: languageCode 
                }, 
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: 'application/docx' });

            // Use file-saver to save the file on the client's machine
            saveAs(file, 'report.docx');
        } catch (error) {
            console.error('Error exporting DOCX:', error);
        } finally {
            setIsExportingDOCX(false);
        }
    };

    /**
     * Organizes chart data by type, using a mapping to specific processing functions.
     * Defaults to an empty array for unhandled types.
     * @param {Array} data - The dataset to process.
     * @returns {Object} - Chart data organized by type.
     */
    const handlePrepareChartData = (data) => {
        let chartDataByType = {};
    
        // Get all unique types from the data
        const uniqueTypes = Array.from(new Set(data.map(item => item.type)));
    
        // Prepare chart data for each unique type using the prepareChartData function
        uniqueTypes.forEach(type => {
            chartDataByType[type] = prepareChartData(data, type);
        });
    
        return chartDataByType;
    };

    /**
     * Organizes comment data by type, applying specific processing functions dynamically.
     * Defaults to an empty array for types without a specific function.
     * @param {Array} data - The dataset to process.
     * @returns {Object} - Processed data organized by type.
     */
    const handlePrepareCommentData = (data) => {
        let commentDataByType = {};

        // Get all unique types from the data
        const uniqueTypes = Array.from(new Set(data.map(item => item.type)));

        // Prepare comment data for each unique type using the prepareCommentData function
        uniqueTypes.forEach(type => {
            commentDataByType[type] = prepareCommentData(data, type);
        });

        return commentDataByType;
    };

    /**
     * Groups chart data by assessment type and workshop, aggregating related items under their specific assessment and workshop categories.
     * @param {string} assessmentType - The type of assessment to filter and group data by.
     * @returns {Object} An object with nested structure where each key is an assessment name, containing objects that represent workshops, which in turn hold arrays of related chart items.
    */
    const groupChartData = (assessmentType) => {
        let groupedData = {};

        if (chartData[assessmentType]) {
            // Iterate over each workshop in the assessmentType
            Object.keys(chartData[assessmentType]).forEach(workshopKey => {

                chartData[assessmentType][workshopKey].forEach(item => {

                    const assessmentKey = item.assessmentName || "default";

                    if (!groupedData[assessmentKey]) {
                        groupedData[assessmentKey] = {};
                    }
                    if (!groupedData[assessmentKey][workshopKey]) {
                        groupedData[assessmentKey][workshopKey] = [];
                    }

                    groupedData[assessmentKey][workshopKey].push(item);
                });
            });
        }
        
        return groupedData;
    };

    /**
     * Groups comment data by assessment type and workshop, organizing items under their respective
     * assessment names and workshops. This structure facilitates easy access to comments related
     * to specific workshops within each assessment.
     * 
     * @param {string} assessmentType - The type of assessment to categorize comments by.
     * @returns {Object} - A nested object with assessment names as keys, each containing workshop keys that map to arrays of related items.
     */
    const groupCommentData = (assessmentType) => {
        let groupedData = {};

        // Check if the assessment type exists in commentData
        if (commentData[assessmentType]) {
            // Iterate over each workshop key in the commentData for the given assessmentType
            Object.entries(commentData[assessmentType]).forEach(([workshopKey, items]) => {
                items.forEach(item => {
                    const assessmentKey = item.assessmentName;

                    // Initialize assessmentKey in groupedData if not present
                    if (!groupedData[assessmentKey]) {
                        groupedData[assessmentKey] = {};
                    }

                    // Initialize workshopKey in groupedData[assessmentKey] if not present
                    if (!groupedData[assessmentKey][workshopKey]) {
                        groupedData[assessmentKey][workshopKey] = [];
                    }

                    // Add the item to the corresponding workshop array
                    groupedData[assessmentKey][workshopKey].push(item);
                });
            });
        }
        
        return groupedData;
    };

    return (
    <Box display="flex" backgroundColor="white" style={{ height: '100vh', overflow: 'auto' }}>
        <Sidebar/>

        <Box flex={1}>

            { /* Title */}
            <Box mt="10px" ml="10px">
                <Topbar title = {getMessage("label_my_results")} />
            </Box>


            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows={`4vh ${currentUser.userStatus === UserType.TEACHER_TRAINER ? "39vh 39vh" : "78vh"}`} gap="20px" ml="20px" mr="20px">   

                { /* Block choose monitoring and session */}
                <Box gridColumn="span 12" gridRow="1" display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <FormControl variant="outlined" size="small"  sx={{ minWidth: 220, marginRight: '20px'}}>
                            <InputLabel id="monitoring">{getMessage("label_choose_monitoring")}</InputLabel>

                            <Select
                                labelId="monitoring"
                                id="monitoring"
                                value={selectedMonitoringId}
                                onChange={handleChangeMonitoring}
                                autoWidth
                                label={getMessage("label_choose_monitoring")}
                            >

                                {monitorings && monitorings.map((monitoring) => (
                                    <MenuItem key={monitoring._id} value={monitoring._id}>
                                        {monitoring.name} 
                                    </MenuItem>
                                ))}
                            </Select>   
                        </FormControl>

                        <FormControl variant="outlined" size="small" sx={{ minWidth: 220 }}>
                            <InputLabel id="day">{getMessage("label_choose_session")}</InputLabel>
                
                            <Select
                                labelId="day"
                                id="day"
                                value={selectedDay}
                                onChange={handleChangeDay}
                                autoWidth
                                label={getMessage("label_choose_session")}
                            >

                                {days && days.map((day, index) => (
                                    <MenuItem key={index} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>   
                        </FormControl>
                    </Box>
                <Box>

                { /* Block buttons export pdf, docx and csv */}
                <Box display="flex" gap={2}>
                    <Button
                        onClick={handleExportDocxReport}
                        variant="contained"
                        sx={{
                            ...buttonStyle,
                            minWidth: 'unset',
                            width: '48px',
                            height: '48px',
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            }
                        }}
                        disabled={!selectedDayAssessments || selectedDayAssessments.length === 0}
                    >
                        {isExportingDOCX ? (
                            <CircularProgress size={28} />
                        ) : (
                            <img 
                                src={DOClogo} 
                                alt="DOCX"
                                style={{ 
                                    width: '28px', 
                                    height: '28px'
                                }}
                            />
                        )}
                    </Button>

                    <Button
                        onClick={handleExportPdfReport}
                        variant="contained"
                        sx={{
                            ...buttonStyle,
                            minWidth: 'unset',
                            width: '48px',
                            height: '48px',
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            }
                        }}
                        disabled={!selectedDayAssessments || selectedDayAssessments.length === 0}
                    >
                        {isExportingPDF ? (
                            <CircularProgress size={28} />
                        ) : (
                            <img 
                                src={PDFlogo} 
                                alt="PDF"
                                style={{ 
                                    width: '28px', 
                                    height: '28px'
                                }}
                            />
                        )}
                    </Button>

                    <Button
                        onClick={handleExportData}
                        variant="contained"
                        sx={{
                            ...buttonStyle,
                            minWidth: 'unset',
                            width: '48px',
                            height: '48px',
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            }
                        }}
                        disabled={!selectedDayAssessments || selectedDayAssessments.length === 0}
                    >
                        <img 
                            src={CSVlogo} 
                            alt="CSV"
                            style={{ 
                                width: '28px', 
                                height: '28px'
                            }}
                        />
                    </Button>
                </Box>
                </Box>

            {/* The 4 blocks of assessment */}
            </Box>
                
                {currentUser && currentUser.userStatus === UserType.TEACHER_TRAINER && (
                    <>
                        <AssessmentTabResult
                            categories={[AssessmentType.TRAINEE_CHARACTERISTICS, AssessmentType.TRAINING_CHARACTERISTICS]}
                            gridRow="2"
                            data={valuesTabOne}
                            onChange={handleChangeTabsOne}
                            groupChartData={groupChartData}
                            groupCommentData={groupCommentData}
                        />

                        <AssessmentTabResult
                            categories={[AssessmentType.IMMEDIATE_REACTIONS, AssessmentType.LEARNING]}
                            gridRow="2"
                            data={valuesTabTwo}
                            onChange={handleChangeTabsTwo}
                            groupChartData={groupChartData}
                            groupCommentData={groupCommentData}
                        />
                        
                        <AssessmentTabResult
                            categories={[AssessmentType.ORGANIZATIONAL_CONDITIONS ,AssessmentType.BEHAVIORAL_CHANGES, AssessmentType.SUSTAINABILITY_CONDITIONS]}
                            gridRow="3"
                            data={valuesTabThree}
                            onChange={handleChangeTabsThree}
                            groupChartData={groupChartData}
                            groupCommentData={groupCommentData}
                        />

                        <AssessmentTabResultWithFilter
                            categories={[AssessmentType.STUDENT_CHARACTERISTICS, AssessmentType.STUDENT_LEARNING_OUTCOMES]}
                            gridRow="3"
                            data={valuesTabFour}
                            onChange={handleChangeTabsFour}
                            groupChartData={groupChartData}
                            groupCommentData={groupCommentData}
                            allEmails={allEmails}
                            selectedUser={selectedUser}
                            handleChangeUser={handleChangeUser}
                        />
                    </>
                )}

                {currentUser && currentUser.userStatus === UserType.TEACHER && (
                    <>
                        <AssessmentTabResult
                            categories={[AssessmentType.STUDENT_CHARACTERISTICS, AssessmentType.STUDENT_LEARNING_OUTCOMES]}
                            data={valuesTabFour}
                            onChange={handleChangeTabsFour}
                            groupChartData={groupChartData}
                            groupCommentData={groupCommentData}
                            fullScreen={true}
                        />
                    </>
                )}
            </Box>
        </Box>
    </Box>);
};

export default Reports;