import React from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Topbar from "../../scenes/global/Topbar";
import { useMessageService } from '../../services/MessageService';
import { buttonStyle } from '../../components/styledComponents';

import logo from "../../assets/medias/logo.svg";
import createMonitoringAssessment from "../../assets/medias/create-monitoring-and-assessment.gif";
import editPreviewOpenAssessment from "../../assets/medias/edit-preview-open-assessment.gif";
import results from "../../assets/medias/results.gif";
import logbook from "../../assets/medias/logbook.gif";
import logoEpfl from "../../assets/medias/logo-epfl.svg";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Home = () => {
  const navigate = useNavigate();
  const { getMessage } = useMessageService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSandboxMode = true;

  const Wave = ({ reverse }) => (
    <svg viewBox="0 0 1200 120" preserveAspectRatio="none" style={{ width: '100%', height: '80px', transform: reverse ? 'rotate(0deg)' : 'rotate(180deg)' }}>
      <path d="M0 0 C 300 100 650 0 1200 100 V 120 H 0 Z" fill="rgba(87,193,202,0.5)"></path>
    </svg>
  );

  const responsiveButtonStyle = {
    ...buttonStyle,
    margin: isMobile ? theme.spacing(1, 0) : theme.spacing(0, 2),
    width: isMobile ? 'auto' : 'auto',
  };

  const responsiveImageStyle = {
    cursor: "pointer",
    borderRadius: "1%",
    maxWidth: "80%",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
    marginBottom: theme.spacing(4),
  };

  return (
    <Box display="flex" flexDirection="column" backgroundColor="white" style={{minHeight: '100vh'}}>
      <Topbar logo={logo} boxShadow={"rgba(0, 0, 0, 0.24) 0px 8px 8px -8px"}/>

      <Wave />

      <Box display="flex" flexDirection="column" alignItems="center" px={isMobile ? 2 : 10}>
        <Typography variant={isMobile ? "h4" : "h2"} sx={{ fontWeight: "bold", textAlign: "center" }} mb={2}>
          {getMessage('dtc_description')}
        </Typography>
        <Typography variant={isMobile ? "h5" : "h3"} align="justify" mt={1} mb={5}>
          {getMessage('dtc_description_2')}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', width: 'auto', mb: 5 }}>
            <Button 
              variant="contained" 
              onClick={() => navigate("/signup", { state: { isSandboxMode }})} 
              sx={responsiveButtonStyle}
            >
            <Typography variant={isMobile ? "h6" : "h4"}>{getMessage('label_signup')}</Typography>
          </Button>
          <Button variant="contained" onClick={() => navigate("/dashboard")} sx={responsiveButtonStyle}>
            <Typography variant={isMobile ? "h6" : "h4"}>{getMessage('label_go_dashboard')}</Typography>
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1, textAlign: 'center' }}>
          <Typography variant={isMobile ? "body1" : "h6"}>{getMessage('label_platform_is_beta')}</Typography>
          <Typography variant={isMobile ? "body1" : "h6"}>{getMessage('label_trial_version')}</Typography>
          <Typography variant={isMobile ? "body1" : "h6"}>
            {getMessage('label_report_bugs')}
            <a href="mailto:contact@digitaltrainingcompanion.ch">contact@digitaltrainingcompanion.ch</a>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <img
            alt=""
            src={logoEpfl}
            style={{
              cursor: "pointer",
              borderRadius: "1%",
              maxWidth: isMobile ? "150px" : "200px",
            }}
          />
        </Box>
      </Box>

      <Wave reverse={true} />

      <Box display="flex" flexDirection="column" alignItems="center" px={isMobile ? 2 : 10} mb={4}>
        <Typography variant={isMobile ? "h4" : "h2"} sx={{ fontWeight: "bold", textAlign: "center" }} mt={4} mb={2}>
          {getMessage('dtc_description_3')}
        </Typography>

        {[
          { title: 'dtc_description_4', description: 'dtc_description_5', image: createMonitoringAssessment },
          { title: 'dtc_description_6', description: 'dtc_description_7', image: editPreviewOpenAssessment },
          { title: 'dtc_description_8', description: 'dtc_description_9', image: results },
          { title: 'dtc_description_10', description: 'dtc_description_11', image: logbook },
        ].map((section, index) => (
          <Box key={index} mb={4}>
            <Typography variant={isMobile ? "h5" : "h3"} sx={{ fontWeight: "bold" }} mt={4} mb={1} textAlign="center">
              {getMessage(section.title)}
            </Typography>
            <Typography variant={isMobile ? "body1" : "h5"} mt={2} mb={2} align="justify">
              {getMessage(section.description)}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="" src={section.image} style={responsiveImageStyle} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Home;