import axios from "axios";
import { BACKEND_URL } from "../config";
import jwt_decode from "jwt-decode";
import { questionBank } from '../assets/questionBank';
import { LearningType } from "./enums";

/**
 * Duplicates a question by creating a new question with similar properties but a unique ID.
 * For non-single-text questions, adds a copy suffix to indicate it's a copy.
 * The new question is inserted immediately after the original in the questions array.
 *
 * @param {Array} questions - The current array of questions.
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {number} questionId - The ID of the question to duplicate.
 */
export const duplicateQuestion = (questions, setQuestions, questionId) => {
    const questionIndex = questions.findIndex(question => question.questionId === questionId);
    if (questionIndex === -1) return; // Early return if question not found

    const originalQuestion = questions[questionIndex];
    const newQuestionId = Math.max(...questions.map(question => question.questionId)) + 1;

    // Create the new question object
    const newQuestion = {
        ...originalQuestion,
        questionId: newQuestionId,
    };

    // Only add copy suffix for non-single-text questions
    if (originalQuestion.questionType !== 'single-text') {
        const copySuffix = " (Copy)";
        const newQuestionLabel = originalQuestion.question.includes(copySuffix)
            ? originalQuestion.question.replace(/ \(Copy(\d*)\)$/, (match, number) =>
                ` (Copy${number ? parseInt(number, 10) + 1 : 2})`)
            : originalQuestion.question + copySuffix;
        const newShortnameLabel = originalQuestion.shortName.includes(copySuffix)
            ? originalQuestion.shortName.replace(/ \(Copy(\d*)\)$/, (match, number) =>
                ` (Copy${number ? parseInt(number, 10) + 1 : 2})`)
            : originalQuestion.shortName + copySuffix;

        newQuestion.question = newQuestionLabel;
        newQuestion.shortName = newShortnameLabel;
    }

    // Insert the duplicated question after the original
    setQuestions(prevQuestions => [
        ...prevQuestions.slice(0, questionIndex + 1),
        newQuestion,
        ...prevQuestions.slice(questionIndex + 1)
    ]);
};

/**
 * Moves a question up or down within its workshop.
 *
 * @param {Array} questions - The current array of questions.
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {string} workshopName - The name of the workshop containing the question.
 * @param {string} questionId - The ID of the question to move.
 * @param {string} direction - The direction to move the question ('up' or 'down').
*/
export const reorderQuestionWithinWorkshop = (questions, setQuestions, workshopName, questionId, direction) => {

    // Filter questions for the specified workshop
    const workshopQuestions = questions.filter(question => question.workshop === workshopName);

    // Find the index of the question to be moved within the workshop
    const questionIndex = workshopQuestions.findIndex(question => question.questionId === questionId);
    if (questionIndex === -1) return; // question not found

    // Check bounds within the workshop's questions
    if ((direction === 'up' && questionIndex === 0) || (direction === 'down' && questionIndex === workshopQuestions.length - 1)) {
        return; // Can't move beyond array bounds within the same workshop
    }

    const moveIndex = direction === 'up' ? questionIndex - 1 : questionIndex + 1;

    // Find the actual indices of these questions in the main questions array
    const actualIndex = questions.findIndex(question => question.questionId === workshopQuestions[questionIndex].questionId && question.workshop === workshopName);
    const actualMoveIndex = questions.findIndex(question => question.questionId === workshopQuestions[moveIndex].questionId && question.workshop === workshopName);

    // Swap the questions in the main array
    const newQuestions = [...questions];
    [newQuestions[actualIndex], newQuestions[actualMoveIndex]] = [newQuestions[actualMoveIndex], newQuestions[actualIndex]];
    setQuestions(newQuestions);
};

/**
 * Adds a new option with empty label and value to the options array of a specific question.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {string} questionId - The ID of the question to which the new option is to be added.
*/
export const addOption = (setQuestions, questionId) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: [...question.options, { label: '', value: '' }] }
                : question
        )
    );
};

/**
 * Removes an option from a specific question's options array based on the option's index.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {string} questionId - The ID of the question from which the option is to be removed.
 * @param {number} optionIndex - The index of the option to be removed from the question's options array.
*/
export const removeOption = (setQuestions, questionId, optionIndex) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: question.options.filter((_, index) => index !== optionIndex) }
                : question
        )
    );
};

/**
 * Handles changes to the options of a question, updating the label and value based on user input.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {Object} e - The event object containing the new option label.
 * @param {string} questionId - The ID of the question being updated.
 * @param {number} optionIndex - The index of the option within the question's options array.
*/
export const changeOption = (setQuestions, e, questionId, optionIndex) => {
    const newLabel = e.target.value;
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? {
                    ...question,
                    options: question.options.map((option, index) =>
                        index === optionIndex ? { ...option, label: newLabel, value: newLabel } : option
                    ),
                }
                : question
        )
    );
};

/**
 * Updates the adoption type of a specific question.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {Object} e - The event object from the adoption type select element.
 * @param {string} questionId - The ID of the question being updated.
 */
export const changeLearningType = (setQuestions, e, questionId) => {
    const newLearningType = e.target.value;
    setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
            question.questionId === questionId ? { ...question, learningType: newLearningType } : question
        )
    );
};

/**
 * Updates the correct answer for a specific question based on user selection.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {Object} event - The event object from the correct answer select element.
 * @param {string} questionId - The ID of the question being updated.
 */
export const changeCorrectAnswer = (setQuestions, event, questionId) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(q => {
            if (q.questionId === questionId) {
                // Map the selected letter values to their corresponding option labels
                const selectedAnswers = event.target.value.map(letterValue => {
                    const optionIndex = letterValue.charCodeAt(0) - 'A'.charCodeAt(0);
                    return q.options[optionIndex]?.label || letterValue;
                });

                return {
                    ...q,
                    correctAnswer: selectedAnswers
                };
            }
            return q;
        })
    );
};

/**
 * Saves the edits made to a question and updates the list of questions.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {string} questionId - The ID of the question being edited.
 * @param {object} newQuestionData - The updated data for the question.
*/
export const saveEdits = (setQuestions, setEditingQuestionId, questionId, newQuestionData) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId ? { ...question, ...newQuestionData } : question
        )
    );
    setEditingQuestionId(null);
};

/**
 * Removes a question from the list based on its workshop and ID.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {string} workshopName - The name of the category to which the question belongs.
 * @param {string} questionId - The ID of the question to be removed.
*/
export const removeQuestion = (setQuestions, workshopName, questionId) => {

    setQuestions(prevQuestions => prevQuestions.filter(question => !(question.workshop === workshopName && question.questionId === questionId)));
};

/**
 * Updates the mandatory status of a question based on user interaction.
 *
 * @param {Function} setQuestions - The state setter function for questions.
 * @param {Object} event - The change event from the mandatory switch.
 * @param {string} questionId - The ID of the question being updated.
 */
export const changeMandatoryStatus = (setQuestions, event, questionId) => {
    setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
            question.questionId === questionId
                ? { ...question, isMandatory: event.target.checked }
                : question
        )
    );
};


/**
 * save survey data to the assessment
 *
 * assessmentId
 * @param {string} assessmentId - The is of the assessment
 * @param {string} surveyData - The data from the survey
 */
export const saveSurveyToAssessment = async (assessmentId, suveyData) => {
    try {
        const token = localStorage.getItem("token");

        // save the survey to the assessmentId
        const response = await axios.put(`${BACKEND_URL}/assessments/${assessmentId}/survey`,
            suveyData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to submit the survey: ' + error.message);
    }
};

/**
 * Fetches existing survey data from the backend based on the current assessment server ID.
 * Updates the state with fetched survey questions, workshop details, and whether the survey has multiple workshops.
 *
 * @param {Function} setQuestions - State setter function for updating the list of questions.
 * @param {Function} setSplitWorkshops - State setter function to update whether there are multiple workshops.
 * @param {Function} setWorkshops - State setter function for updating the list of workshops.
 * @param {Function} setInitialQuestions - State setter function for updating the list of initial questions.
 * @param {string} currentAssessmentServerId - The current assessment server ID used to fetch the survey.
 * @param {Array} predifinedQuestionIds - List of predinied questions.
 */
export const fetchExistingSurvey = async (setQuestions, setSplitWorkshops, setWorkshops, setInitialQuestions, currentAssessmentServerId, predifinedQuestionIds, languageCode) => {

    try {
        const token = localStorage.getItem("token");
        const decodedToken = jwt_decode(token);
        const sandbox = decodedToken.sandbox;

        // get the all the surveys from this assessment
        const response = await axios.get(`${BACKEND_URL}/survey`, {
            params: {
                currentAssessmentServerId: currentAssessmentServerId,
                sandbox: sandbox
            }
        });

        // if there is a response
        if (response.data && response.data.survey && Array.isArray(response.data.survey) && response.data.survey.length > 0) {

            // Check for workshops values and set splitWorkshops
            const workshopsArray = Array.from(new Set(response.data.survey
                .map(survey => survey.workshop)
                .filter(workshop => workshop)))

            if (Array.isArray(workshopsArray) && workshopsArray.length > 0) {
                setSplitWorkshops(true);
                setWorkshops(workshopsArray);
            }

            const updatedQuestions = response.data.survey.map(question => ({
                ...question,
                options: question.choices.map(choice => ({ label: choice, value: choice })),
                correctAnswer: Array.isArray(question.correctAnswer) ? question.correctAnswer : (question.correctAnswer ? [question.correctAnswer] : []),
            }));

            // update the array
            setQuestions(updatedQuestions);

        } else {
            // if no questions, get the predifinned questions for the example
            let predifinedQuestion = fetchPredifinedQuestions(predifinedQuestionIds, languageCode);
            setQuestions(predifinedQuestion);
            setInitialQuestions(predifinedQuestion);
        }
    } catch (error) {
        console.error('Error fetching existing survey data:', error);
    }
};


/**
 * Fetch the predefined questions for a specific survey configuration
 */
function fetchPredifinedQuestions(predifinedQuestionIds, languageCode) {

    return predifinedQuestionIds.map(id => ({
        ...questionBank[languageCode][id],
        workshop: "",
        questionId: id,
        options: questionBank[languageCode][id].options.map(option => ({
            value: option,
            label: option
        }))
    }));
}
