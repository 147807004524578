import React, {useState, useCallback} from 'react';
import { Box, Button, Radio, Checkbox, FormControl, FormLabel, FormControlLabel, RadioGroup, Typography, TextField, Slider } from '@mui/material';
import { Field } from 'formik';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import draftToHtml from 'draftjs-to-html';

const SurveyQuestion = ({
  question,
  shortName = "",
  context,
  options,
  fieldName,
  type,
  isMandatory = false,
  competencies = [],
  min = 0,
  max = 10, 
  titleFontSize = '24px',
  optionFontSize = '18px',
  disabled = false,
  correctAnswer,
  explanation,
  assessmentType = "",
  displayCorrectAnswer = true
}) => {

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);

  const isOptionDisabled = useCallback(() => {
    const assessmentTypesToDisable = ["Learning", "Student learning outcomes"];
    return disabled || (assessmentTypesToDisable.includes(assessmentType) && explanation && showExplanation);
  }, [disabled, assessmentType, showExplanation, explanation]);

  const handleAnswerSelect = useCallback((event, option) => {
    if (!isOptionDisabled()) {
        setSelectedAnswer(option);
        const isCorrect = option === correctAnswer;
        setIsAnswerCorrect(isCorrect);
        setIsAnswered(true);
        setShowExplanation(true);
    }
  }, [isOptionDisabled, correctAnswer]);

  const isCorrectAnswer = useCallback((option) => {
    if (!displayCorrectAnswer || !correctAnswer || type === "checkbox") return false;
    return option.label === correctAnswer;
  }, [displayCorrectAnswer, correctAnswer, type]);

  const correctAnswerCreateSurvey = {};

  const htmlContent = typeof context === 'object' ? draftToHtml(context) : context;

  return (
    <Box margin="20px" display="flex" flexDirection="column" width="95%" alignItems="center">
      <FormControl component="fieldset" fullWidth disabled={disabled}>
        <FormLabel sx={{ fontSize: titleFontSize, m: "0px 10px 10px 0px", fontWeight: "bold"}}>
          {question}
          {isMandatory && <span style={{ color: 'red' }}> *</span>}
          {disabled && shortName && (
            <>
              {" - "}
              <span style={{ fontStyle: 'italic' }}>{shortName}</span>
            </>
          )}
        </FormLabel>

        {type === "single-text" ? (
          <Box sx={{ fontSize: optionFontSize, mt: 2 }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        ) : type === "radio-unordered" || type === "radio-ordered" ? (
          <RadioGroup 
            aria-label={fieldName} 
            name={fieldName}
            onChange={(event) => handleAnswerSelect(event, event.target.value)}>
            
            {options.map((option, index) => {
              const averageCharsPerLine = 75;
              const estimatedLines = Math.ceil(option.label.length / averageCharsPerLine);

              return (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Field as={Radio} type="radio" name={fieldName} />}
                  sx={{
                    ...(estimatedLines > 2 && { mb: '15px' }),
                  }}
                  label={
                    <Typography
                      sx={{
                        fontSize: optionFontSize,
                        ...(disabled && correctAnswer && isCorrectAnswer(option) ? correctAnswerCreateSurvey : {}),
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  disabled={isOptionDisabled()}
                />
              );
            })}
          </RadioGroup>
        ) : type === "checkbox" ? (
          options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Field as={Checkbox} type="checkbox" name={fieldName} />}
              label={<Typography sx={{ fontSize: optionFontSize }}>{option.label}</Typography>}
              disabled={disabled}
            />
          ))
        ) : type === "slider" && (
          <Field name={fieldName}>
            {({ field, form }) => (
              <Slider
                value={field.value || min}
                onChange={(e, newValue) => {
                  form.setFieldValue(field.name, newValue);
                }}
                valueLabelDisplay="auto"
                min={min}
                max={max}
                sx={{ m: "0px 0px 0px 0px"}}
                disabled={disabled}
              />
            )}
          </Field>
        )}

        {type === "text" && (
          <Field
            multiline
            as={TextField}
            name={fieldName}
            sx={{ m: "0px 20px 0px 0px"}}
            disabled={isOptionDisabled()}
            value={disabled ? "" : undefined}
            rows={4}
          />
        )}

        {/* Display explanation with color based on answer correctness */}
        <Box display="flex" gap="10px" marginTop="20px">
          {(showExplanation || disabled) && explanation && (
            <Box display="flex" maxWidth="95%">
              <Button 
                variant="outlined"
                size="small"
                style={{
                  color: disabled ? "green" : (isAnswerCorrect ? "green" : "red"),
                  borderColor: disabled ? "blue" : (isAnswerCorrect ? "green" : "red"),
                  backgroundColor: "white",  
                  borderRadius: '20px',
                  padding: '5px 10px',
                }}
                aria-label="explanation"
              >
                <TipsAndUpdatesIcon style={{ color: disabled ? "blue" : (isAnswerCorrect ? "green" : "red"), marginRight: '5px' }} />
                <Typography style={{ fontSize: optionFontSize, color: disabled ? "blue" : (isAnswerCorrect ? "green" : "red") }}>
                  {explanation}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>

        <Box display="flex" flexWrap="wrap" gap="10px" marginTop="20px">
          {competencies.map((competency, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Button 
                variant="outlined"
                size="small"
                style={{color: "rgb(102,102,102)", borderColor: "lightgrey", backgroundColor: "white", borderRadius: '20px', padding: '5px 10px',}}>
                <Typography style={{ fontSize: '0.7rem', padding: '0 5px' }}>
                  {competency}
                </Typography>
              </Button>
            </Box>
          ))}
        </Box>

      </FormControl>
    </Box>
  );
};

export default SurveyQuestion;