import { useState, useEffect, useRef } from 'react';
import { ResponsiveBar } from "@nivo/bar";
import { QuestionType } from "../utils/enums";
import { redYellowGreenPalette } from "../components/styledComponents";
import { useMessageService } from '../services/MessageService';
import './BarChartReports.css';

const BarChartReports = ({ data }) => {
  const chartRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { getMessage } = useMessageService();

  // color definition
  const pastelBlue = 'rgb(173, 216, 230)';
  const brightPastelBlue = 'rgb(193, 236, 250)';

  // Calculate the maximum number of choices across all data items
  const maxChoices = data.reduce((max, item) => Math.max(max, item.choices.length), 0);
  const keys = Array.from({ length: maxChoices }, (_, index) => `choice_${index + 1}`);
  
  // Transform data
  const transformedData = data.map(item => ({
    ...item,
    shortName: item.uniqueQuestionKey,
    ...Object.fromEntries(item.counts.map((count, index) => [`choice_${index + 1}`, count]))
  }));

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!chartRef.current || !tooltipData) return;

      const chartBounds = chartRef.current.getBoundingClientRect();
      
      // Calculate relative position with padding
      const xPosRelative = (event.clientX - chartBounds.x) / chartBounds.width;
      const yPosRelative = (event.clientY - chartBounds.y) / chartBounds.height;
      
      // Add padding to keep tooltip within bounds
      const xPos = (0.6 * xPosRelative + 0.2) * chartBounds.width;
      const yPos = (0.6 * yPosRelative + 0.2) * chartBounds.height;

      setMousePosition({ x: xPos, y: yPos });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [tooltipData]);

  const getBarColor = (bar) => {
    const choiceNumber = parseInt(bar.id.replace('choice_', ''), 10) - 1;
    const questionData = data.find(item => item.uniqueQuestionKey === bar.data.shortName);

    if (!questionData) return 'grey';

    // Handle cases where correctAnswer is an empty string
    if (questionData.correctAnswer === "") {
      return pastelBlue; // Use blue for questions with no correctAnswer
    }

    // Handle MCQ
    if (questionData.correctAnswer !== undefined) {
      const correctAnswerIndex = questionData.choices.indexOf(questionData.correctAnswer);
      return choiceNumber === correctAnswerIndex ? redYellowGreenPalette[9] : redYellowGreenPalette[0];
    }

    // Handle unordered questions
    if (questionData.type === QuestionType.RADIO_UNORDERED || 
        questionData.type === QuestionType.CHECKBOX) {
      return choiceNumber % 2 === 0 ? pastelBlue : brightPastelBlue;
    }

    // Handle numeric choices
    const numChoices = questionData.choices.length;
    if (numChoices > 1) {
      const paletteIndex = Math.floor(choiceNumber / (numChoices - 1) * 
        (redYellowGreenPalette.length - 1));
      return redYellowGreenPalette[paletteIndex];
    }

    return numChoices === 1 ? 
      redYellowGreenPalette[Math.floor(redYellowGreenPalette.length / 2)] : 
      'grey';
  };

  const handleTooltip = ({ id, value, indexValue }) => {
    const choiceIndex = parseInt(id.replace('choice_', ''), 10) - 1;
    const questionData = data.find(item => item.uniqueQuestionKey === indexValue);

    if (!questionData) return null;

    const choice = questionData.choices?.[choiceIndex] ?? 'Invalid choice';
    const names = questionData.names?.[choiceIndex]?.join(', ') ?? 'No answers';

    return {
      question: questionData.question,
      choice,
      value,
      names
    };
  };

  return (
    <div ref={chartRef} style={{ position: 'relative', height: '90%' }}>
      <ResponsiveBar
        data={transformedData}
        keys={keys}
        indexBy="shortName"
        margin={{ top: 15, right: 15, bottom: 15, left: 150 }}
        layout="horizontal"
        groupMode="stacked"
        padding={0.1}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={getBarColor}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: false,
          legendPosition: "middle",
          legendOffset: -30
        }}
        enableLabel={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        role="application"
        onMouseEnter={(bar) => {
          const tooltipContent = handleTooltip(bar);
          if (tooltipContent) setTooltipData(tooltipContent);
        }}
        onMouseLeave={() => setTooltipData(null)}
        tooltip={() => null}
        barAriaLabel={e => `${e.indexValue}: ${e.id} - ${e.value}`}
      />
      {tooltipData && (
        <div 
          className="custom-tooltip" 
          style={{ 
            top: mousePosition.y, 
            left: mousePosition.x 
          }}
        >
          <strong>{tooltipData.question}</strong><br />
          <span>{tooltipData.choice}: {tooltipData.value}</span><br />
          <span>{getMessage("label_respondents")}: {tooltipData.names}</span>
        </div>
      )}
    </div>
  );
};

export default BarChartReports;